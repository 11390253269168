import { ChangeDetectorRef, Component, OnDestroy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, RouterModule, ActivatedRoute } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { ClockComponent } from './shared/components/clock/clock.component';
import { routes } from './app.routes';
import { AuthService } from './core/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'tm-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    RouterOutlet,
    ClockComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent implements OnDestroy {
  readonly authService = inject(AuthService);
  messages: string[] = [];

  mobileQuery: MediaQueryList;
  navItems = routes.filter(route => route.path != "**");
  private _mobileQueryListener: () => void;
  title: string = "";
  titleData$: any;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, 
    private router: Router, private activatedRoute: ActivatedRoute,
     private titleService: Title) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          setTimeout(() => {
            this.title = this.titleService.getTitle();
          }, 10);
        }
      },
    });
  }

  ngOnInit() {
    this.authService.getUser()
      .then(user => {
        if (user) {
          this.authService.setEmpId(user);
        }
      }).catch(err => this.addError(err));    
  }  

  private addError(msg: string | Error) {
    this.messages.push('Error: ' + (msg instanceof Error ? msg.message : msg));
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
