import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-signin-callback',
  template: `<i class="fas fa-spinner fa-spin"></i>`,
  styles: '',
  standalone: true,
  imports: [],
})
export class SigninCallbackComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);

  ngOnInit() {
    
    this.authService.userManager.signinCallback().then(user => {
      //const empId = user?.profile['CnsIdEmployee'] as string;
      if (user) {
        this.authService.setEmpId(user);
      }
    }).finally(() => {
      const redirectURL = localStorage.getItem('redirectURL');
      this.router.navigate([redirectURL]);
    });
  }
}
