import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { GlobalErrorInterceptor } from './core/interceptors/global-error.interceptor';
import { AccessInterceptor } from './core/interceptors/access.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
  {
    provide: ErrorHandler,
    useClass: GlobalErrorInterceptor
  },
  provideHttpClient(withInterceptorsFromDi()),
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AccessInterceptor,
    multi: true
  },
  provideClientHydration(), provideAnimations(), provideHttpClient(withFetch())

  ]
};
