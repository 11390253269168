<div class="tm-container" [class.tm-is-mobile]="mobileQuery.matches">
    <!-- Toolbar -->
    <mat-toolbar class="tm-toolbar" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
        <div class="tm-logo-menu-container" fxFlex fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <img class="tm-mcd-logo" src="../../assets/images/MCD.png" fxFlexAlign="start">
        </div>
        <header class="tm-header" fxFlex="grow" fxLayout="row" fxLayoutAlign="center space-between">
            <span class="tm-app-name">{{ title }}</span>
            <span class="tm-time">
                <tm-clock></tm-clock>
            </span>
        </header>
    </mat-toolbar>
    <!-- Side nav -->
    <mat-sidenav-container class="tm-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 64 : 0">
        <!--- Nav list-->
        <mat-sidenav class="tm-sidenav" opened #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches" fixedTopGap="64">
            <div class="tm-nav-bar">
                <mat-nav-list class="tm-nav-list">
                    <a mat-list-item routerLink="/time-recording" routerLinkActive="is-active">
                        <mat-icon mat-list-icon>
                            <img src="../assets/images/house_black.svg" alt="House Icon" class="default-img">
                            <img src="../assets/images/house_green.svg" alt="House Icon" class="active-img">
                        </mat-icon>
                        <span routerLinkActive="is-active" class="menu-item">ZEITERFASSUNG</span>
                    </a>
                    <a mat-list-item routerLink="/time-data-table" routerLinkActive="is-active">
                        <mat-icon mat-list-icon>
                            <img src="../assets/images/computer_black.svg" alt="Computer Icon" class="default-img">
                            <img src="../assets/images/computer_green.svg" alt="Computer Icon" class="active-img">
                        </mat-icon>
                        <span routerLinkActive="is-active" class="menu-item">ZEITDATENTABELLE</span>
                    </a>
                </mat-nav-list>
                <div class="cns-logo">
                    <img src="../../assets/images/cunds.svg" alt="Cunds Icon" class="cns-icon">
                    <span class="logo-text">© C&S Computer and Software Gmbh - 2023</span>
                </div>
            </div>
        </mat-sidenav>

        <!--- Nav content-->
        <mat-sidenav-content class="tm-content">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>