import { Component, AfterViewInit, NgZone, OnDestroy } from '@angular/core';
import { DatePipe, AsyncPipe } from '@angular/common';
import { ClockService } from './clock.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tm-clock',
  standalone: true,
  imports: [DatePipe, AsyncPipe],
  templateUrl: './clock.component.html',
  styleUrl: './clock.component.scss'
})

export class ClockComponent {
  time: any | undefined;
  errorMessage: any;
  message: any;
  clockSubscription$: Subscription | undefined;
  date = new Date();


  constructor(private clockService: ClockService, private ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      this.clockSubscription$ = this.clockService.getDate().subscribe({
        next: result => {
          this.ngZone.run(() => this.time = result);    
        }
      });
    });
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.clockSubscription$) {
      this.clockSubscription$.unsubscribe();
    }
  }
}
