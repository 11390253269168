import { Routes } from '@angular/router';
import { SigninCallbackComponent } from './core/signin-callback.component';
import { AuthGuardService } from './core/guards/auth-guard.service';

export const routes: Routes = [
    {
        path: 'time-recording',
        title: "ZEITERFASSUNG",
        data: {
            icon: "computer_green.svg"
        },
        loadComponent: () =>
            import('./components/time-recording/time-recording.component').then(mod => mod.TimeRecordingComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'time-data-table',
        title: "ZEITDATENTABELLE",
        data: {
            icon: "house_black.svg"
        },
        loadComponent: () =>
            import('./components/time-data-table/time-data-table.component').then(mod => mod.TimeDataTableComponent),
        canActivate: [AuthGuardService]
    },
    { path: 'identity/signInCallback', component: SigninCallbackComponent }
];
