import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, filter, from, mergeMap, switchMap, take, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { ErrorPopupComponent } from "../../shared/components/error-popup/error-popup.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class AccessInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(
        private authService: AuthService,
        private dialog: MatDialog
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.addAuthHeader(req))
            .pipe(
                mergeMap((modifiedReq) => next.handle(modifiedReq)),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    switch (error.status) {
                        case 404:
                            return this.handle401Error(req, next);
                        case 400:
                            errorMessage = 'Bad Request'
                            break;
                        default:
                            errorMessage = `Error! ${error.status} . Please try again after some time.`;
                            break;
                    }
                    if (error instanceof HttpErrorResponse) {
                        this.dialog.open(ErrorPopupComponent, {
                            data: {
                                message: `${error.error.Message}`
                            },
                            width: '300px',
                            disableClose: true
                        });
                        return throwError(() => new Error('Server side error:' + error.message));
                    } else {
                        console.log('Interceptor caught a client-side error:', error);
                        return throwError(() => new Error('Client-side error: ' + errorMessage));
                    }
                })
            );

    }

    async addAuthHeader(req: HttpRequest<any>): Promise<HttpRequest<any>> {
        const accessToken = await this.authService.getAccessToken();
        if (accessToken) {
            req = req.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
        }
        return req;
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return from(this.authService.renewToken()).pipe(
                switchMap((user: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(user!.access_token);
                    return next.handle(this.addToken(request, user!.access_token));
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((jwt) => {
                    return next.handle(this.addToken(request, jwt));
                })
            );
        }
    }

    private addToken(request: HttpRequest<any>, token: any) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            },
        });
    }
}