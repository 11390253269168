import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler } from "@angular/core";

export class GlobalErrorInterceptor implements ErrorHandler {
    constructor() { }
    handleError(error: any): void {
        if (error instanceof HttpErrorResponse) {
            console.log('Http Error: ', error);
        } else {
            console.log('Non-Http Error:', error)
        }
    }
}